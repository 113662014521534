<template>
  <div class="container">
    <div class="detail-index-bg">
      <div class="detail-index-bottom clear">
        <Crumbs/>
        <!-- <div class="detail-index-bottom-bread">
          <span @click="goBack()" style="color:black;cursor:pointer;margin-right:0.1rem">资讯动态</span> /
          <span style="margin-left:0.1rem">{{ info.title }}</span>
        </div> -->
        <div class="detail-index-bottom-left">
          <div class="detail-index-bottom-left-content clear">
            <div class="detail-index-bottom-left-content-pic">
              <img
                src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_left.png"
                alt=""
              />
            </div>

            <div class="detail-index-bottom-left-content-title">
              {{ info.title }}
            </div>
            <div class="detail-index-bottom-left-content-des">
              <ul class="detail-index-bottom-left-content-des-ul clear">
                <li class="detail-index-bottom-left-content-des-ul-li">
                  <span class="detail-index-bottom-left-content-des-ul-li-label"
                    >发布时间：</span
                  >
                  {{ info.publish_time | parseTime("{y}-{m}-{d}") }}
                </li>
                <li class="detail-index-bottom-left-content-des-ul-li">
                  <span class="detail-index-bottom-left-content-des-ul-li-label"
                    >作者：</span
                  >
                  {{ info.publisher_name }}
                </li>
                <li class="detail-index-bottom-left-content-des-ul-li">
                  <span class="detail-index-bottom-left-content-des-ul-li-label"
                    >点击量：</span
                  >
                  {{ info.visit_count }}
                </li>
              </ul>
            </div>
            <div class="detail-index-bottom-left-content-linePic">
              <img
                src="https://image.bookgo.com.cn/webculture/jm/detail/detail-common-crossline.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="detail-index-bottom-left-bottom-summary"
            v-html="info.content"
          >
            {{ info.content }}
          </div>
        </div>
      </div>
      <div class="downFile-all" v-if="attachsArray.length > 0">
        <div class="file-title">
          <img style="vertical:middle" src="https://image.bookgo.com.cn/%20webculture/jm/fujianjinmen.png" />
          <span style="margin-left:0.05rem;vertical-align:middle">附件</span>
        </div>
        <div class="file-list" v-for="(item,i) in attachsArray" v-bind:key="i">          
          <span>{{item.name}}</span>
          <a :href=item.url style="display: inline-block;"> 
            <span class="downButton">下载</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { info as articleinfo, list } from "@/api/article";
import Crumbs from "@/views/components/crumbs";
export default {
  data() {
    return {
      id: null,
      info: {},
      detailType: "news",
      attachsArray:[],
      tid:"",
    };
  },
  components: { Crumbs },
  created() {
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.tid = this.$route.query.tid;
    // window.scroll(0, 0);
    this.getInfo();
  },
  watch: {
    $route(newV) {
      document.documentElement.scrollTop = 0;
      this.tid = newV.query.tid;
      this.getInfo()
    },
  },
  methods: {
    handleClosed() {},
    getInfo() {
      articleinfo({ id: this.tid }).then((res) => {
        console.log(res)
        console.log(res.data.article.attachs.length)
        if(res.data.article.attachs.length>0){
          var attachs = JSON.parse(res.data.article.attachs)
            this.attachsArray = attachs
            console.log(this.attachsArray)
        }
        
        const regex = new RegExp("<img", "gi");
        res.data.article.content = res.data.article.content.replace(
          regex,
          `<img style="max-width: 100%; height: auto;margin:0 auto"`
        );
        this.info = res.data.article;
        console.info(this.info);
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
</style>
<style lang="scss" scoped>

</style>
